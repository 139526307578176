import { ICompany } from "../components/Home/UploadFileTabPanel/interfaces";
import { IPermissionObj, IRoles } from "../components/Roles/interface";

export interface ApiResponse {
  status: string;
  data:
    | IRoles[]
    | ICompany[]
    | IPermissionByCompanyIdResponse[]
    | PermissionsResponse[]
    | UserResponse[]
    | DepartmentResponse[];
}

export interface UserResponse {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  departmentId: number;
  company: Company;
  roleId: number;
}

export interface ICreateUpdateRole {
  roleId?: number;
  roleName: string;
  companyId: number;
  permissionsObj: IPermissionObj[];
}

export interface IPermissionByCompanyIdResponse {
  id: number;
  name: string;
  companyId: number;
  roleId: number;
}

interface Role {
  id: number;
  name: string;
}

interface Company {
  id?: number;
  name?: string;
}

export interface PermissionsResponse {
  id: number;
  name: string;
  role: Role;
  company: Company;
}

export interface PermissionRequestBody {
  permissionId?: number;
  name: string;
  companyId: number;
}

export enum EFormAction {
  CREATE = "create",
  UPDATE = "update",
}

export interface IAddAccountRequest {
  firstName: string;
  lastName: string;
  email: string;
  companyId: string | number;
  action?: EFormAction;
  departmentId?: number | string | null;
  id?: number;
  roleId: string | number;
  templateId?: string;
  websiteRedirect?: string;
}

export interface DepartmentResponse {
  id: number;
  name: string;
  companyId: number;
}
