import {
  Dialog,
  DialogTitle,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  DialogActions,
  Button,
  Box,
  FormControl,
  NativeSelect,
  OutlinedInput,
  TextField,
  Chip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { EFormAction, IAddAccountRequest } from "../../api/interface";
import { useAppSelector } from "../../store/reduxHooks";
import { set } from "lodash";
import { IRoles } from "../Roles/interface";
import { handleGetRolesByCompanyIdApi } from "../../api/roles";
import { handleDepartmentsByCompanyApi } from "../../api/department";

const steps = ["Details", "Others"];

interface IProps {
  openModal: boolean;
  handleClose: () => void;
  formAction: EFormAction;
  isLoading: boolean;
  handleUpdateUser: () => void;
  handleCreateUser: () => void;
  formData: IAddAccountRequest;
  handleOnChange: (key: string, value: string) => void;
}

const UserModal: React.FC<IProps> = ({
  openModal,
  handleClose,
  formAction,
  handleCreateUser,
  handleUpdateUser,
  formData,
  handleOnChange,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const companyData = useAppSelector((state) => state.companySlice.companyData);
  const [roleData, setRoleData] = useState<IRoles[]>([]);
  const [selectedRole, setSelectedRole] = useState<IRoles>();
  const [departments, setDepartments] = useState<any[]>([]);

  const handleRolesByCompany = async () => {
    try {
      const result = await handleGetRolesByCompanyIdApi(
        formData.companyId as number
      );
      setRoleData(result);
      if (formAction == EFormAction.UPDATE) {
        setSelectedRole(result.find((role) => role.id == formData.roleId));
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    if (formData.companyId) {
      handleRolesByCompany();
    }
  }, [companyData, formData]);

  useEffect(() => {
    if (formData.companyId) {
      const fetchDepartmentsByCompany = async (companyId: number) => {
        try {
          const response = await handleDepartmentsByCompanyApi(companyId);
          setDepartments(response);
        } catch (err) {
          console.log(err);
        }
      };

      fetchDepartmentsByCompany(formData.companyId as number);
    }
  }, [formData.companyId]);

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Box sx={{ marginTop: 5 }}>
            <FormControl sx={{ width: "100%" }}>
              <NativeSelect
                inputProps={{
                  name: "companyId",
                  id: "uncontrolled-companyId",
                }}
                variant="outlined"
                name="companyId"
                value={formData.companyId}
                input={<OutlinedInput sx={{ borderRadius: 2 }} />}
                onChange={(e) => {
                  const { name, value } = e.target;
                  handleOnChange(name, value);
                }}
              >
                <option value={""}>Select a company*</option>
                {companyData.map((result, index) => (
                  <option value={result.id} key={index}>
                    {result.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
            <Box sx={{ marginTop: 5 }}>
              <TextField
                label="First name*"
                id="firstName"
                fullWidth
                type="text"
                value={formData.firstName}
                name="firstName"
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                error={false}
              />
            </Box>
            <Box sx={{ marginTop: 5 }}>
              <TextField
                label="Last name*"
                id="lastName"
                fullWidth
                type="text"
                value={formData.lastName}
                name="lastName"
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                error={false}
              />
            </Box>
            <Box sx={{ marginTop: 5 }}>
              <TextField
                label="Email*"
                id="email"
                fullWidth
                type="email"
                value={formData.email}
                name="email"
                onChange={(e) => handleOnChange(e.target.name, e.target.value)}
                error={false}
                inputProps={{ maxLength: 245 }}
              />
            </Box>
          </Box>
        );

      case 1:
        return (
          <Box sx={{ marginTop: 5 }}>
            <FormControl sx={{ width: "100%" }}>
              <NativeSelect
                inputProps={{
                  name: "roleId",
                  id: "uncontrolled-roleId",
                }}
                variant="outlined"
                name="roleId"
                value={formData.roleId}
                input={<OutlinedInput sx={{ borderRadius: 2 }} />}
                onChange={(e) => {
                  const { name, value } = e.target;
                  handleOnChange(name, value);
                  setSelectedRole(
                    roleData.find((role) => role.id == parseInt(value))
                  );
                }}
              >
                <option value={0}>Select a role*</option>
                {roleData.map((result, index) => (
                  <option value={result.id} key={index}>
                    {result.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>

            <Box>
              <Typography sx={{ marginTop: 1 }}>
                Permissions for selected role
              </Typography>
              {selectedRole?.permissionsObj &&
                selectedRole?.permissionsObj.map((permission, index) => (
                  <Chip
                    label={permission.permissionName}
                    key={index}
                    sx={{ marginLeft: 2 }}
                  />
                ))}
            </Box>
            <FormControl sx={{ width: "100%", marginTop: 5 }}>
              <NativeSelect
                inputProps={{
                  name: "departmentId",
                  id: "uncontrolled-departmentId",
                }}
                variant="outlined"
                name="departmentId"
                value={formData.departmentId}
                input={<OutlinedInput sx={{ borderRadius: 2 }} />}
                onChange={(e) => {
                  const { name, value } = e.target;
                  handleOnChange(name, value);
                }}
              >
                <option value={undefined}>Select a department*</option>
                {departments.map((result, index) => (
                  <option value={result.id} key={index}>
                    {result.name}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
            <FormControl sx={{ width: "100%", marginTop: 5 }}>
              <NativeSelect
                inputProps={{
                  name: "websiteRedirect",
                  id: "uncontrolled-website-redirect",
                }}
                variant="outlined"
                name="websiteRedirect"
                value={formData.websiteRedirect}
                input={<OutlinedInput sx={{ borderRadius: 2 }} />}
                onChange={(e) => {
                  const { name, value } = e.target;
                  handleOnChange(name, value);
                }}
              >
                <option value={undefined}>Select a wesbite redirect*</option>
                <option value={process.env.REACT_APP_GLOBAL_WEBSITE_URL}>
                  Global Website
                </option>
                <option value={process.env.REACT_APP_TAKEDA_WEBSITE_URL}>
                  Takeda Website
                </option>
                <option value={process.env.REACT_APP_ORGANON_WEBSITE_URL}>
                  Organon Website
                </option>
              </NativeSelect>
            </FormControl>
          </Box>
        );
    }
  };
  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby="user-modal-title"
      aria-describedby="user-modal-description"
    >
      <DialogTitle id="company-modal-title">
        {formAction == EFormAction.CREATE ? "Create User" : "Update User"}
      </DialogTitle>
      <DialogContent sx={{ width: 500 }}>
        <Stepper activeStep={1}>
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {renderStepContent()}
      </DialogContent>
      <DialogActions>
        {activeStep == 0 ? (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setActiveStep(1)}
          >
            Next
          </Button>
        ) : (
          <Box>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setActiveStep(0)}
              sx={{ marginRight: 2 }}
            >
              Back
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={
                formAction == EFormAction.CREATE
                  ? handleCreateUser
                  : handleUpdateUser
              }
            >
              {formAction == EFormAction.CREATE ? "Create" : "Update"}
            </Button>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};
export default UserModal;
